import React from "react"
import Layout from "../components/layout"
import Hero from "../components/Hero/Hero"
import SEO from "../components/seo"
import Cards from "../components/Cards"
import Testemunhos from "../components/Testemunhos"
import Parceiros from "../components/Parceiros"

const IndexPage = () => {
return(
  <Layout> 
    <SEO title="S3LF" />
    <Hero />
  <Cards />
  <Testemunhos />
  <Parceiros />
  </Layout>
)
  }

export default IndexPage
