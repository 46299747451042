import React from 'react'
import styled from 'styled-components'
import Klockner from '../images/Klockner.png'
import Medtronic from '../images/Medtronic.png'
import placeclubs from '../images/placeclubs.png'
import promofitness from '../images/promofitness.png'
import unidesporto from '../images/unidesporto.png'



const Parceiros = () => {

    return (
      
        <ParceirosContainer>
  
            <Description>Partners</Description>
            <ContentWrapper>
      <Image> <img src={promofitness} width={100}
  height={50} alt="promofitness" /></Image>          
      <Image> <img src={placeclubs} width={100}
  height={40} alt="placeclubs" /></Image>          
     <Image> <img src={unidesporto} width={100}
  height={40} alt="unidesporto" /></Image>
     <Image> <img src={Medtronic} width={100}
  height={40} alt="Medtronic" /></Image>
     <Image><img src={Klockner} width={80}
  height={60}  alt="Klockner"/></Image>         
              
            </ContentWrapper>
        </ParceirosContainer>
  
    )
}

export default Parceiros


const ParceirosContainer = styled.div`
width: 100%;
background: #fff;
color: #000;
padding: 2rem calc((100vw - 1300px) / 2 );
height: 100%;

`

const Description = styled.p`
text-align: center;

margin-bottom: 5rem;
font-size: 50px;
margin-top: 8px;
  color: rgb(170, 117, 39);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
`

const ContentWrapper = styled.div`
display: grid;
grid-template-columns: repeat(5, 5fr);
padding:  2rem;
padding-right: 1.5rem;
line-height: 1.5em;



@media screen and (max-width: 768px) {
    grid-template-columns:  1fr 1fr;
}
`


const Image = styled.div`
padding: 1rem;

`