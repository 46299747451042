import React, {useState} from 'react';
import { Link } from 'gatsby';
import {Button} from './Button';
import Popup from './Popup';


function CardItem(props) {

  const [buttonPopup, setButtonPopup] = useState(false);
  
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='S3LF'
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <Button className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--medium' 
          onClick={() => setButtonPopup(true)}>
            {props.text}</Button> 
            <div className='cards__item__text'>
            <Popup 
            trigger={buttonPopup} setTrigger={setButtonPopup} >
              {props.text2}<br/><br/>{props.text3}<br/><br/>{props.text4}<br/><br/>{props.text5}<br/><br/>{props.text6}
            </Popup> </div>
            </div>
            

        </Link>
      </li>
      
    </>
  );
};

export default CardItem