import React from 'react'
import {IoMdClose} from 'react-icons/io'
import './Popup.css'

function Popup(props) {
    return (props.trigger) ? (
        <div className='popup'>
            <div className="popup-inner">
                <button className='close-btn' onClick={() => props.setTrigger(false)}> <IoMdClose /> </button>
                {props.children}
                
            </div>
        </div>
    ) : '';
}

export default Popup;
