import React from 'react'
import styled from 'styled-components'


const Testemunhos = () => {

    return (

        <TestemunhosContainer>
            <Description>Testimonials</Description>
            <ContentWrapper>
                <ColumnOne>
                <Testemunho>
                    
                    <p>"Pedro is a rigorous, honest, assertive, demanding, and attentive professional, with an unusual relational and communicational capacity, able to motivate us continuously in search of excellence in practice. <br/> He is a reliable person who takes each one of us to develop and pursue the goals individually intended. <br/>The gain I get from his work goes far beyond physical and psychological well-being, being for me a source of an essential balance with applicability in all areas of life. <br/>It will always be for me a reference and an example of a top professional !!"</p>
                    <h3>Dra. Teresa Kreisch, Doctor</h3>
                </Testemunho>
                </ColumnOne>
                <ColumnTwo>
                <Testemunho>
                    
                    <p>"I could say that I have been a student of Núria for ten years, but I am more than that because she is more than an Instructor. <br/>For being the reason that what I have received so far, was more than the opportunity to unite the body and the mind.<br/> It was gaining a clear sense to get to know me and thought of wanting to continue this journey of constant learning and overcoming."</p>
                    <h3>Sílvia Gonçalves, Technical Assistant</h3>
                </Testemunho>
                <Testemunho>
                    
                    <p>"Pedro is endowed with unique human and professional qualities.<br/> His classes are permanent challenges. Trying to reach them enhances our skills and makes us stronger. Irreplaceable!"</p>
                    <h3>Dra. Márcia Passos, Lawyer</h3>
                </Testemunho>
               </ColumnTwo>
               <ColumnThree>
               <Testemunho>
                    
                    <p>"I've been practicing physical exercise with Pedro for several years.<br/> I started with group classes and felt immediate improvements in my spine. Then I complemented it with a personal training solution.<br/> I strongly recommend it not only for the technical solution but also for the mental approach that it establishes."</p>
                    <h3>Mafalda Vasconcelos, EDP Administrative Council</h3>
                </Testemunho>
               </ColumnThree>
            </ContentWrapper>
        </TestemunhosContainer>
          
    )
}

export default Testemunhos


const TestemunhosContainer = styled.div`
width: 100%;
background: #fcfcfc;
color: #000;
padding: 5rem calc((100vw - 1300px) / 2 );
height: 100%;

`

const Description = styled.p`
text-align: center;
padding-left: 0rem;
margin-bottom: 5rem;
font-size: 50px;
margin-top: 8px;
  color: rgb(170, 117, 39);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
`

const ContentWrapper = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
padding: 0 2rem;


@media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
}
`

const ColumnOne = styled.div`
padding-left: 2rem;
display: grid;
grid-template-rows: repeat(1fr, 1fr);
padding-right: 1.5rem;
line-height: 1.5em;



`
const ColumnTwo = styled.div`
display: grid;
grid-template-rows: repeat(1fr, 1fr);
padding-left: 1.5rem;
line-height: 1.5em;


`

const ColumnThree = styled.div`
display: grid;
grid-template-rows: repeat(1fr, 1fr);
padding-left: 1.5rem;
line-height: 1.5em;

`

const Testemunho = styled.div`
padding-top: 1rem;
padding-right: 2rem;
font-style: italic;
padding-bottom: 2rem;

h3 {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-style: italic;
    color: #163461;
}

p {
    color: #274b81;
}
`

