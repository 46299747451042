import React from 'react';
import './Cards.css';
import {Link} from 'gatsby'
import CardItem from './CardItem';
import ps1 from '../images/ps1.jpg';
import ps2 from '../images/ps2.jpg';
import ps3 from '../images/ps3.jpg';
import './Button.css';
import {Button} from './Button';

function Cards() {


  return (

     
    <div className='cards'>
    <h1 >Why S3LF?</h1>
    <div className='cards__container'  >
      <div className='cards__wrapper'>
        <ul className='cards__items'>
          <CardItem  src={ps1} text='Specific Sessions'
          text2='- Helps to prevent burnouts;'
            text3='- Helps to prevent episodes of anxiety or depression;'
            text4='- Assists in developing strategies to manage exaggerated stress levels;'
            text5='- Reduces the effects of exposure to ergonomic and psychosocial risks and consequently the development of occupational diseases;'
            text6='- Fosters and strengthens team spirit.'/>
          <CardItem src={ps2}  text='Online Services'
          text3='The different aspects of our work can all be developed in online format.'  
          text4='Throughout the confinement period created by the pandemic, we were able to experiment and prove the efficiency of our methods even in an element of remote communication.'/>
          <CardItem src={ps3} text='Classes to private clients'
          text4='From yoga or pilates classes to exercise psychology sessions aimed at specific goals.'/>
        </ul>

      </div> 

      <Link to='/contactos' className='hero-btns'>
                   <Button 
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
    >
         CONTACT US
        </Button>
               
         </Link>
    </div>
   
  </div>
 
);
}

export default Cards