import React from 'react'
import {Link} from 'gatsby'
import {Button} from '../Button'
import './Hero.css'


function Hero() {

    return (

       <div className='hero-container'>
      <p>Self Conscious</p>
      <p>Self Improvement</p>
      <p>Self Sufficient</p>

      <Link to='/quemsomos' className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        
        >
          ABOUT US
        </Button>
               
         </Link>
       </div>
    )
}

export default Hero